#news-post {
	@import "../posts/section-news-cards";

	.section-news-single-content {
		@extend %container-large;

		display: flex;
		padding-top: 36px;

		.sub-news-single-content {
			padding-top: 52px;
			padding-right: 36px;

			h1 {
				font-weight: $font-weight-bold;
				margin-bottom: 16px;

				@include mq(tablet-down) {
					max-width: 270px;
					font-size: $font-size-larger-larger-larger;
					margin-bottom: 10px;
				}
			}

			strong {
				display: block;
				font-weight: $font-weight-light;
				color: $color-light-blue;
				margin-bottom: 22px;
			}

			.news-summary p {
				color: $color-black;
				font-size: $font-size-larger;
				line-height: 32px;

				@include mq(tablet-down) {
					color: $color-medium-dark-grey;
					font-size: $font-size-standard;
					line-height: 25px;
				}
			}

			.news-single-image-mobile {
				display: none;
				margin: 46px 0;

				img {
					width: 100%;
				}

				@include mq(tablet-down) {
					display: block;
				}
			}

			.share-buttons {
				margin: 32px 0 46px;

				img {
					height: 28px;
					width: 28px;
					object-fit: contain;
				}

				@include mq(tablet-down) {
					display: none;
				}
			}

			.news-content {
				color: $color-black;
				font-size: $font-size-med;
				line-height: 28px;

				// Subtitle.
				strong {
					font-weight: $font-weight-bold;
					color: $color-black;
					margin-bottom: 0;
				}

				@include mq(tablet-down) {
					color: $color-medium-dark-grey;
					font-size: $font-size-standard;
					line-height: 25px;
				}
			}

			@include mq(tablet-down) {
				padding-top: 0;
				padding-right: 0;
			}
		}

		.sub-news-single-image {
			width: 50%;

			img {
				height: 413px;
				object-fit: cover;
				border-radius: 32px;
			}

			@include mq(tablet-down) {
				display: none;
			}
		}
	}

	.section-news-cards {
		margin-bottom: 48px;

		.sub-news-cards .news-card {
			margin: 0 30px 20px 0;
		}

		@include mq(tablet-down) {
			margin-top: 64px;
			margin-bottom: 64px;
		}
	}
}
