#page {
	.section-content {
		padding: 60px 0;

		h1 {
			font-size: $font-size-massive;
			font-weight: bold;
			margin-bottom: 45px;
		}

		h2, h3 {
			margin-bottom: 20px;
		}

		.wrap-content {
			@extend %container;
		}
	}
}
