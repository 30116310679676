.section-hero-image {
	margin-bottom: 48px;

	.slick-dots {
		bottom: 32px;
		width: 100%;
		justify-content: center;

		@include mq(tablet-down) {
			display: none !important;
		}
	}

	.hero-image-text-container {
		position: absolute;
		width: 100vw;
		display: flex;
		justify-content: start;
		top: 0;
		flex-direction: column;

		@include mq(tablet-down) {
			display: flex;
			justify-content: flex-end;
			height: 100%;
			width: 100vw;
			top: 0;
			padding: 0 0 40px;
			z-index: 1;

			h1 {
				text-align: center;
			}

			p {
				display: none;
			}

			.mod-hero-btn {
				margin: auto;
				height: 40px;
				font-size: $font-size-large;
			}
		}


		.hero-image-text {
			@extend %container-large;

			margin-top: 64px;

			h1 {
				font-size: 54 / $font-base * 1rem;
				font-weight: bold;
				line-height: 62px;
				max-width: 570px;
				width: 100%;

				@include mq(tablet-down) {
					font-size: $font-size-largest;
					line-height: 39px;
					margin: 0 auto 25px;
				}
			}

			p {
				max-width: 465px;
				font-size: $font-size-larger;
				line-height: 34px;
			}

			.hero-button-container {
				display: flex;
			}
		}
	}

	.hero-image-container > img {
		width: 100%;
		height: 624px;
		object-position: center;
		object-fit: cover;
	}

	.sub-hero-image-text-mobile {
		display: none;
	}

	@include mq(tablet-down) {
		margin-bottom: 0;

		.hero-image-overlay {
			position: absolute;
			left: 0;
			top: 0;
			background: linear-gradient(0deg, rgb(255, 255, 255) 0%, rgba(0, 0, 0, 0) 50%);
			height: 100%;
			width: 100%;
		}

		.hero-image-container {
			img {
				height: 483px;
			}
		}

		.sub-hero-image-text-mobile {
			display: block;
			margin-top: 16px;
			padding: 0 $container-gutter-mobile;
			text-align: center;
			font-size: $font-size-large;
			color: $color-medium-dark-grey;
			font-weight: $font-weight-bold;
		}
	}
}
