#news {
	@import "../contents/section-hero-image";
	@import "../posts/section-news-cards";

	.mod-hero-btn {
		margin-top: 18px;
		width: unset;
		height: 51px;
		font-size: $font-size-larger;
		padding: 0 28px;
		border-radius: 30px;
	}

	.section-hero-image {
		margin-bottom: 62px;
		position: relative;

		.hero-image-text-container {
			z-index: 99;

			.hero-image-text {
				h1 {
					font-size: $font-size-massive;
					max-width: 460px;
				}

				.mod-hero-btn > span {
					margin: 0 18px;
				}
			}
		}

		.hero-image-container > img {
			height: 441px;
		}

		.hero-image-overlay {
			@extend %white-fade;
		}
	}
}
