#contact {
	.section-contact-us-form {
		// A bit of a hack to get around the %container-large below.
		background: linear-gradient(270deg, rgba(0, 0, 0, 0) 60%, rgba(249, 249, 249, 1) 60%);

		.section-contact-us-form-inner {
			@extend %container-large;

			display: flex;

			.sub-contact-us-content {
				width: 45%;
				padding-top: 76px;
				background: $color-light-grey;
				padding-left: $container-gutter-mobile;
				padding-right: $container-gutter-mobile;
				padding-bottom: 50px;

				h1 {
					font-weight: $font-weight-bold;

					@include mq(mobile) {
						font-size: $font-size-larger-larger-larger;
					}
				}

				.content {
					font-size: $font-size-med;
					line-height: 30px;
					margin-bottom: 36px;

					@include mq(mobile) {
						font-size: $font-size-small;
						line-height: 23px;
					}
				}

				.contact-details {
					color: $color-dark-grey;
					font-size: $font-size-larger;
					line-height: 36px;

					p {
						margin-bottom: 0;
					}

					@include mq(mobile) {
						font-size: $font-size-standard;
						line-height: 28px;
					}
				}

				.contact-address {
					color: $color-dark-grey;
					font-size: $font-size-large;
					line-height: 30px;
					margin-top: 28px;

					@include mq(mobile) {
						font-size: $font-size-standard;
						line-height: 28px;
					}
				}

				@include mq(mobile) {
					width: 100%;
					padding-bottom: 20px;
					padding-top: 24px;
				}
			}

			.sub-contact-us-form {
				@import "_partial-form";

				width: 55%;
				padding-top: 76px;
				padding-left: 64px;
				padding-right: 178px;
				margin-bottom: 96px;

				h2 {
					font-size: $font-size-larger;
				}

				form {
					margin-top: 34px;

					.submit-button {
						align-self: flex-start;
						border-radius: 26px;
						font-size: $font-size-larger;
						margin-top: 24px;
						max-width: 240px;
						min-height: 51px;
						width: 100%;

						> span {
							margin: 0 86px;
						}

						@include mq(mobile) {
							font-size: $font-size-small-med;
							min-height: 40px;
							width: 100%;
						}
					}
				}

				.sub-thanks {
					font-size: $font-size-large;
					display: none;
					padding-top: 35px;
				}

				@include mq(tablet-down) {
					padding-left: 32px;
				}


				@include mq(mobile) {
					width: 100%;
					padding-top: 36px;
					padding-left: $container-gutter-mobile;
					padding-right: $container-gutter-mobile;
					margin-bottom: 72px;
				}
			}

			@include mq(mobile) {
				flex-direction: column;
				width: 100%;
				max-width: 100%;
			}
		}

		@include mq(mobile) {
			background: $color-white;
		}
	}

	.section-map {
		#map {
			height: 490px;
		}
	}
}
