.section-image-carousel {
  background: $color-light-grey;
  padding: 70px 0 100px;

  h2 {
    font-size: $font-size-h1;
    text-align: center;
    margin-bottom: 24px;

    @include mq(tablet-down) {
      font-size: $font-size-larger;
    }
  }

  .section-image-carousel-slide-container {
      @extend %flex-center;

    $image-width: 205px;

    width: ($image-width * 5);
    margin: auto;

    .slide {
      img {
        height: 165px;
        object-fit: contain;
        width: $image-width;

        @include mq(tablet-down) {
          margin: auto;
        }
      }
    }

    @include mq(tablet-down) {
      width: 100vw;
    }
  }

  .slick-dots {
    justify-content: center;
    margin-bottom: -26px;

    li {
      margin: 0;
      width: 16px;

      button {
        opacity: 1;
        background: $color-ngage-pink;
      }

      &.slick-active button {
        opacity: .2;
        background: $color-ngage-pink;
      }
    }
  }
}
