.section-video-modal {
	@extend %flex-center;

	position: fixed;
	top: 0;
	left: 0;
	width: 100vw;
	height: 100vh;
	background: rgba($color-dark-grey, 0.9);
	z-index: 1000;

	&.mod-video-modal-section-hidden {
		display: none;
	}

	.sub-video-container {
		height: 550px;
		max-width: 985px;
		position: relative;
		text-align: center;
		width: 100%;

		video {
			height: 100%;
			max-width: 100%;

			@include mq(tablet-down) {
				width: 100%;
			}
		}

		iframe {
			height: 100%;
			width: 100%;
		}

		.close-video-modal-btn {
			position: absolute;
			top: 0;
			right: 0;
			width: 32px;
			height: 32px;
			margin-top: -64px;
			margin-right: -32px;
			cursor: pointer;

			svg {
				height: 25px;
				width: 25px;
			}

			@include mq(tablet-down) {
				width: 14px;
				height: 14px;
				margin-top: -24px;
				margin-right: 0;
			}
		}

		@include mq(tablet-down) {
			height: auto;
			width: calc(100% - 64px);
			margin-bottom: 86px;
		}
	}

	@include mq(tablet-down) {
		background: $color-medium-darker-grey;
		height: 100vh;
		width: 100vw;
		display: flex;
		align-items: end;
		justify-content: center;
	}
}
