.section-hero-title {
	position: relative;
	height: 270px;

	.sub-section-hero-title {
		@extend %flex-center;

		justify-content: flex-start;
		z-index: 1;
		position: absolute;
		left: 0;
		top: 0;
		width: 100%;
		height: 100%;

		.section-hero-title-inner {
			@extend %container-large;

			h1 {
				@extend %container-large;

				margin: 0;
				font-size: $font-size-massive;
				font-weight: bold;
				max-width: 440px;

				@include mq(tablet-down) {
					font-size: $font-size-largest;
					line-height: 39px;
					max-width: 150px;
					margin: 0 0 0 20px;
				}
			}
		}
	}

	> img {
		position: absolute;
		left: 0;
		top: 0;
		width: 100%;
		height: 100%;
		object-position: center;
		object-fit: cover;
	}

	@include mq(tablet-down) {
		height: 166px;
	}
}
