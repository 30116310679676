.section-content-and-box {
	@extend %container-large;

	@extend %flex-space-between;

	align-items: flex-start;
	margin: 80px auto 26px;

	.sub-content {
		width: 60%;
		font-size: $font-size-larger;
		padding-right: 78px;
		line-height: 42px;

		h2 {
			margin-bottom: 32px;

			@include mq(tablet-down) {
				font-size: $font-size-larger;
				margin-bottom: 16px;
			}
		}

		@include mq(tablet-down) {
			@include container-large;

			width: 100%;
			padding-right: 0;
			text-align: center;
			font-size: $font-size-small-med;
			line-height: 24px;
			margin-bottom: 26px;
		}
	}

	.sub-box {
		width: 40%;

		.buttons {
			.mod-section-content-and-box-button {
				@include flex-space-between;

				padding: 0 32px;
				width: 100%;
				font-size: $font-size-larger;
				height: auto;
				min-height: 51px;
				border-radius: 44px;
				margin-bottom: 14px;

				&:last-of-type {
					margin-bottom: 68px;
				}

				> span {
					@include flex-space-between;

					width: 100%;
					display: flex;
					align-items: center;
					margin: 6px 0;
				}

				@include mq(tablet-down) {
					font-size: $font-size-small-med;
					min-height: 40px;

					&:last-of-type {
						margin-bottom: 24px;
					}
				}
			}
		}

		.box {
			width: 100%;
			padding: 32px;
			background: $color-light-grey;
			border-radius: 32px;

			h2 {
				font-size: $font-size-larger-larger;
				margin-bottom: 18px;

				@include mq(tablet-down) {
					font-size: $font-size-large;
					margin-bottom: 12px;
				}
			}

			p {
				font-size: $font-size-small-med;
			}

			.mod-box-cta-button {
				justify-content: center;
				margin-top: 36px;
				height: 51px;
				border-radius: 44px;
				font-size: $font-size-larger;
				width: 255px;

				@include mq(tablet-down) {
					font-size: $font-size-small-med;
					height: 36px;
					width: 122px;
					margin-top: 26px;
				}
			}

			@include mq(tablet-down) {
				padding: 24px 16px;
			}
		}

		@include mq(tablet-down) {
			width: 100%;
		}
	}

	@include mq(tablet-down) {
		flex-direction: column;
		margin: 32px auto 48px;
	}
}
