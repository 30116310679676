.mod-overflow-hidden {
	overflow: hidden;
}

.header-main {
	align-items: center;
	background: $color-white;
	display: flex;
	height: $header-height;
	position: fixed;
	z-index: 999;
	top: 0;
	left: 0;
	width: 100%;

	.pink-chevron-down {
		margin-top: 2px;
		transition: 0.1s;
	}

	&.header-main-desktop {
		border-bottom: 1px solid $color-hairline;
	}

	.sub-container {
		width: 100%;
		height: 100%;
		display: flex;
		margin: 0 24px;
	}

	nav {
		@extend %flex-grow;

		align-items: center;
		display: flex;

		.nav-main-logo-container {
			img {
				height: 58px;
				width: 284px;

				@media screen and (max-width: 1415px) {
					height: auto;
					width: 215px;
				}

				@media screen and (max-width: 1220px) {
					width: 170px;
				}
			}
		}

		> .nav-main-links-container {
			@extend %flex-grow;

			@extend %flex-space-around;

			@media only screen and (max-width: $container-max-width - 1) {
				flex: 0 0 auto;
			}
		}

		ul.list-nav {
			@extend %list-default;

			margin-left: 40px;

			@media screen and (max-width: 1300px) {
				margin-left: 30px;
			}

			@media screen and (max-width: 1100px) {
				margin-left: 20px;
			}

			&.list-nav-main-buttons {
				li {
					padding: 0;

					&:first-of-type {
						margin-right: 11px;

						@media screen and (max-width: 1220px) {
							margin-right: 6px;
						}
					}

					& > a {
						font-size: 17 / $font-base * 1rem;
						height: 40px;

						@media screen and (max-width: 1415px) {
							font-size: $font-size-small-med;
							width: 115px;
						}

						@media screen and (max-width: 1220px) {
							font-size: $font-size-standard;
							width: 103px;
						}

						span {
							margin-right: 6px;
						}

						&.mod-donate-btn {
							img {
								@media screen and (max-width: 1220px) {
									width: 23px;
								}
							}
						}
					}
				}
			}

			li {
				display: inline-block;
				padding: 0 10px;

				@media screen and (max-width: 1415px) {
					padding: 0 8px;
				}

				@media screen and (max-width: 1220px) {
					padding: 6px;
				}

				& > a:not(.mod-shop-btn):not(.mod-donate-btn) {
					color: $color-dark-grey;
					font-size: 17 / $font-base * 1rem;
					font-weight: $font-weight-bold;

					@media screen and (max-width: 1300px) {
						font-size: $font-size-small-med;
					}

					@media screen and (max-width: 1220px) {
						font-size: $font-size-standard;
					}

					&.mod-nav-main-sublink-parent-btn {
						@extend %flex-center;

						cursor: pointer;
					}

					.pink-chevron-down {
						margin-left: 8px;
					}
				}

				&.mod-open {
					.pink-chevron-down {
						transform: rotate(180deg);
					}
				}
			}

			li.has-sub-menu {
				position: relative;

				&.mod-open {
					ul.list-sub-menu {
						@extend %flex-column;

						&:focus {
							outline: none;
						}
					}
				}

				ul.list-sub-menu {
					display: none;
				}
			}
		}

		ul.list-nav.list-nav-desktop {
			ul.list-sub-menu {
				z-index: 1;
				position: absolute;
				padding: 13px 0;
				width: 207px;
				top: 56px;
				left: -12px;
				background: $color-white;
				box-shadow: 6px 6px 8px 0 rgba(0, 0, 0, 0.03);
				border-radius: 12px;

				li {
					padding: 11px 3px;
					margin: 0 14px;

					a {
						font-size: $font-size-standard;
					}
				}
			}
		}

		@media only screen and (max-width: $container-max-width - 1) {
			justify-content: flex-start;
		}
	}

	@include mq(tablet-down) {
		display: none;
	}
}

.header-main.header-main-mobile {
	display: none;

	.nav-main-mobile {
		display: none;
	}

	justify-content: space-between;
	align-items: center;
	height: $mobile-header-height;
	padding: 0 20px;

	.nav-main-mobile {
		display: none;
		flex-direction: column;
		height: 100vh;
		width: 100vw;
		position: absolute;
		top: 0;
		left: 0;
		z-index: 1;
		background: white;

		ul, li {
			margin: 0;
			padding: 0;
		}

		.close-mobile-menu-button-container {
			@extend %flex-center;

			position: absolute;
			top: 0;
			left: 0;
			justify-content: flex-end;
			width: 100vw;
			height: $mobile-header-height;
			padding: 0 20px;

			.mod-close-mobile-menu-btn {
				cursor: pointer;
			}
		}

		.list-nav-mobile-container {
			height: calc(100vh - 61px);
			overflow-y: scroll;
			padding-bottom: 50px;
			width: 100%;
		}

		.list-nav.list-nav-mobile {
			@extend %flex-column;

			width: 100%;

			li {
				width: 100%;
				min-height: 65px;
				text-align: center;

				.pink-chevron-down {
					transform: rotate(270deg);
				}

				&.mod-open {
					.pink-chevron-down {
						transform: rotate(360deg);
					}
				}

				a {
					@extend %flex-center;

					margin-top: 16px;
					font-size: $font-size-larger;
				}
			}

			ul.list-sub-menu {
				li {
					@extend %flex-center;

					min-height: 42px;

					&:first-child {
						margin-top: 6px;
					}

					&:last-child {
						margin-bottom: 24px;
					}
				}

				a {
					color: $color-medium-grey;
					font-size: $font-size-large;
					max-width: 250px;

					// Visually center by offsetting width of parent-menu's arrow icon.
					margin: 0 10px 0 0;
				}
			}
		}

		.list-nav-main-buttons {
			@extend %flex-column;

			@extend %flex-center;

			margin: 35px 0 0;

			@include mq(tablet-down) {
				margin-top: 25px;
			}


			li {
				&:first-of-type {
					margin-bottom: 20px;
				}

				a {
					align-items: center;
					display: flex;
					font-size: $font-size-large;

					&.mod-shop-btn, &.mod-donate-btn {
						width: 151px;
					}

					&.mod-donate-btn {
						margin-left: 0;

						img {
							margin-left: 4px;
						}
					}
				}
			}
		}
	}

	a.home-logo img {
		height: 38px;
	}

	img.mod-burger-btn {
		height: 25px;
		width: 25px;
		background-repeat: no-repeat;
		background-position: center;
		cursor: pointer;
	}

	@include mq(tablet-down) {
		display: flex;

		&.mod-open {
			.nav-main-mobile {
				display: flex;
				padding-top: 70px;
			}
		}
	}
}

.section-cookies {
	@extend %container-large;

	align-items: center;
	background: $color-dark-blue;
	border-radius: 6px;
	bottom: 19px;
	display: flex;
	height: 63px;
	left: 50%;
	padding: 0 21px;
	position: fixed;
	transform: translateX(-50%);
	width: 100%;
	z-index: 999;

	@include mq(tablet-down) {
		height: auto;
		padding: 20px;
	}


	&.mod-hide {
		display: none;
	}

	.sub-content {
		align-items: center;
		display: flex;
		width: 100%;

		@include mq(tablet-down) {
			flex-direction: column;
		}


		.sub-text {
			flex: 1;

			& > p {
				color: $color-white;
				font-size: 15px;
				font-weight: $font-weight-regular;
				margin-bottom: 0;

				a {
					font-weight: $font-weight-semibold;
					color: $color-white;
				}
			}
		}

		.sub-btns {
			align-items: center;
			display: flex;
			margin-left: 70px;

			@include mq(tablet-down) {
				margin-left: 0;
				margin-top: 20px;
			}


			.btn {
				background: $color-white;
				color: $color-black;
				display: inline-block;
				font-size: $font-size-standard;
				font-weight: $font-weight-regular;
				height: 36px;
				transition: background .3s ease;
				width: 111px;

				@include mq(tablet-down) {
					font-size: $font-size-small;
					padding: 4px 0;
					width: 115px;
				}


				&:first-of-type {
					margin-right: 10px;
				}

				&:hover {
					background: rgba($color-white, 0.25);
				}
			}
		}
	}
}
