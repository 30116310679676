.section-testimonials {
  @extend %flex-center;

  background: $color-light-grey;
  margin-bottom: 0;

  li.slick-active button {
    background: $color-light-pink;
  }

  .sub-testimonials-image {
    display: flex;

    img {
      @media only screen and (min-width: ($mq-tablet-size + 1)) {
        height: 100%;
        width: 100%;
        object-fit: contain;
        object-position: right;
      }

      @include mq(tablet-down) {
        width: 100%;
        height: 406px;
        object-fit: cover;
        object-position: top;
      }
    }

    @media only screen and (min-width: ($mq-tablet-size + 1)) {
      position: absolute;
      width: 38%;
      overflow: hidden;
      height: 642px;
      right: 0;
    }

    @include mq(tablet-down) {
      width: 100%;
    }
  }

  .testimonials-slide-container {
    @extend %list-default;
  }

  .testimonials-slide {
    margin: 0;

    @media only screen and (min-width: ($mq-tablet-size + 1)) {
      max-width: 707px;

      p {
        font-size: $font-size-h3;
        line-height: 39px;
      }

      strong {
        font-size: $font-size-large;
      }
    }
  }

  .sub-testimonials {
    @media only screen and (min-width: ($mq-tablet-size + 1)) {
      @include container-large();

      height: 100%;
      padding: 100px 0;
    }

    @include mq(tablet-down) {
      width: 100%;
      padding-bottom: 52px;
    }

    .slick-dots {
      @media only screen and (min-width: ($mq-tablet-size + 1)) {
        margin-bottom: -30px;
      }

      @include mq(tablet-down) {
        justify-content: center;
        margin-bottom: -16px;
      }
    }

    h2 {
      @media only screen and (min-width: ($mq-tablet-size + 1)) {
        font-size: $font-size-massive;
        font-weight: $font-weight-bold;
        margin-bottom: 52px;
      }

      @include mq(tablet-down) {
        margin-top: 49px;
        margin-bottom: 20px;
        text-align: center;
        font-size: $font-size-larger-larger;
      }
    }

    p {
      @media only screen and (min-width: ($mq-tablet-size + 1)) {

      }

      @include mq(tablet-down) {
        margin: 0 38px;
        text-align: center;
        font-size: $font-size-med;
      }
    }

    strong {
      @include mq(tablet-down) {
        margin-top: 20px;
        text-align: center;
        display: block;
        font-size: $font-size-med;
      }
    }
  }

  @media only screen and (min-width: ($mq-tablet-size + 1)) {
    height: 642px;
    margin-top: 108px;
  }

  @include mq(tablet-down) {
    margin-top: 32px;
    flex-direction: column;
  }
}
