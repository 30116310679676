#refer-your-child {
	@import "_section-form";

	.section-form {
		padding-bottom: 250px;
	}

	.close-download-form-btn {
		display: none;
	}
}
