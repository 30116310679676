h1,
h2,
h3,
h4,
h5 {
	color: $headings-color;
	font-family: $headings-font;
	font-weight: $headings-font-weight;
	line-height: $headings-line-height;
	padding: 0;
	margin-top: 0;
}

h1 {
	font-size: $font-size-h1;
}

h2 {
	font-size: $font-size-h2;
	font-weight: $font-weight-bold;
	margin: 0;
}

h3 {
	font-size: $font-size-h3;
	font-weight: $font-weight-bold;
}

h4 {
	font-size: $font-size-h4;
}

h5 {
	font-size: $font-size-h5;
}

h6 {
	font-size: $font-size-h6;
}

p {
	margin: 0 0 20px;
}

strong {
	font-weight: $font-weight-semibold;
}

::selection {
	background: rgba($color-primary, 0.25);
}

::-moz-selection {
	background: rgba($color-primary, 0.25);
}
