.section-large-quote {
  min-height: 431px;
  height: 431px;

  .sub-large-quote-container {
    @include mq(tablet-down) {
      padding: 24px;
    }
  }
}

.section-full-width-cta {
  .btn {
    min-width: 178px;

    @include mq(tablet-down) {
      min-width: 100px;
    }
  }
}

.section-content-and-quote {
  .sub-content {
    @include mq(tablet-down) {
      margin-bottom: 0;
    }
  }
}
