#resources-for-families {
	@import "section-hero-title";
	@import "section-content-and-box";

	.section-content-and-box {
		.sub-box {
			.box {
				p {
					font-size: $font-size-larger;
					line-height: 42px;

					@include mq(tablet-down) {
						font-size: $font-size-small-med;
						line-height: 31px;
					}
				}
			}
		}
	}
}
