#family-support {
	@import "section-hero-title";
	@import "section-content-and-box";

	.section-family-support-content {
		margin: 80px auto 80px;

		strong {
			display: block;
			margin-top: 32px;
			margin-bottom: 32px;
		}

		.sub-content {
			& > ul {
				margin: 0 0 32px;

				li {
					@include mq(tablet-down) {
						text-align: left;
						margin-bottom: 10px;
					}
				}
			}
		}

		.sub-box {
			.buttons {
				.btn {
					@include flex-space-between;

					padding: 0 32px;
					width: 100%;
					font-size: $font-size-larger;
					height: auto;
					min-height: 51px;
					border-radius: 44px;
					margin-bottom: 14px;

					> span {
						@include flex-space-between;

						width: 100%;
						display: flex;
						align-items: center;
						margin: 6px 0;
					}

					@include mq(tablet-down) {
						font-size: $font-size-small-med;
						min-height: 40px;
					}
				}

				&.top-buttons {
					.btn {
						margin-bottom: 32px;
					}
				}

				&.bottom-buttons {
					.btn {
						margin-top: 32px;
						margin-bottom: 32px;
					}
				}
			}

			img {
				width: 100%;
				border-radius: 32px;
				margin-bottom: 32px;
			}

			.box {
				p {
					font-size: $font-size-larger;
					line-height: 42px;

					@include mq(tablet-down) {
						font-size: $font-size-small-med;
						line-height: 31px;
					}
				}
			}
		}

		@include mq(tablet-down) {
			margin: 32px auto 48px;
		}
	}
}
