#meet-the-team {
	@import "section-hero-title";
	@import "section-image-cards";
	@import "section-image-carousel";
	@import "section-read-more-content-modal";

	.section-our-patron {
		padding: 53px 135px 82px;
		background: $color-light-grey;

		h2 {
			text-align: center;
			margin-bottom: 42px;

			@include mq(tablet-down) {
				font-size: $font-size-larger;
				margin-bottom: 8px;
			}
		}

		.sub-content {
			@extend %container-large;

			display: flex;

			.content {
				@extend %flex-column;

				@extend %flex-space-between;

				align-items: flex-start;

				.our-patron-paragraph {
					p {
						font-size: $font-size-larger;
						line-height: 42px;

						@include mq(tablet-down) {
							font-size: $font-size-small-med;
							line-height: 24px;
							text-align: center;
						}
					}
				}

				.our-patron-footer {
					display: flex;

					strong {
						font-size: $font-size-larger;
					}

					p {
						font-size: $font-size-large;
						line-height: 28px;
						max-width: 500px;
					}

					img {
						max-width: 287px;
						max-height: 123px;
						object-fit: cover;

						@include mq(tablet-down) {
							margin: auto;
						}
					}

					@include mq(tablet-down) {
						flex-direction: column;
						text-align: center;
						margin: auto;
					}
				}
			}

			.image-container {
				@extend %flex-end;

				width: 350px;
				flex: 0 0 350px;

				img {
					height: 279px;
					width: 235px;
					border-radius: 36px;
					object-fit: cover;
				}

				@include mq(tablet-down) {
					@include flex-center;
				}
			}

			@include mq(tablet-down) {
				flex-direction: column;
				align-items: center;
				justify-content: center;
			}
		}

		@include mq(tablet-down) {
			padding: 30px 0 0;
		}
	}
}
