#support-partners {
	@import "section-hero-title";
	@import "section-image-cards";

	.section-image-cards-container .section-image-cards .list-cards-container .list-cards li p {
		margin-top: 16px;

		@include mq(tablet-down) {
			margin-top: 10px;
		}
	}
}
