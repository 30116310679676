/* LINKS */

a {
	color: $color-link;
	text-decoration: none;

	&:active {
		background-color: transparent;
	}

	&:hover {
		color: $color-link-hover;
	}

	&:active,
	&:focus {
		color: $color-link-active;
		border: 0 none;
		outline: 0 none;
	}
}

/* GENERAL BUTTONS */

.btn {
	@extend %nofocus;

	@extend %noselect;

	border: none;
	outline: none;
	cursor: pointer;

	&.btn-primary {
		@extend %flex-center;

		width: 120px;
		height: 40px;
		border-radius: 20px;
		background-color: $color-buttons;
		color: $color-white;
		font-weight: $font-weight-bold;
		transition: all 0.1s ease;

		&:hover,
		&:focus {
			background: highlight-by($color-buttons, 5%);
		}

		&:disabled, &[disabled] {
			background: $color-text !important;
			box-shadow: 0 0 0 2px $color-text !important;
			color: $color-borders-light !important;
			border: 1px solid $color-text;
		}

		&:active {
			box-shadow: none;
			transition: none;
		}

		&.mod-ghost {
			background: transparent;
			color: $color-buttons;

			&:hover,
			&:active,
			&:focus {
				color: $color-white;
				background: highlight-by($color-buttons, 5%);
				box-shadow: 0 0 0 2px highlight-by($color-buttons, 5%);
			}
		}

		&.mod-link {
			padding: 0;
			background: transparent;
			color: $color-buttons;
			box-shadow: none;

			& + .btn {
				margin-left: 10px;
			}

			&:hover,
			&:active,
			&:focus {
				color: $color-buttons;
				background: transparent;
				box-shadow: none;
			}
		}
	}

	&.btn-secondary {
		@extend %flex-center;

		width: 120px;
		height: 40px;
		border-radius: 20px;
		background-color: $color-buttons-secondary;
		color: $color-white;
		font-weight: $font-weight-bold;
		transition: all 0.1s ease;

		&:hover,
		&:focus {
			background: highlight-by($color-buttons-secondary, 5%);
		}

		&:disabled, &[disabled] {
			background: $color-text !important;
			box-shadow: 0 0 0 2px $color-text !important;
			color: $color-borders-light !important;
			border: 1px solid $color-text;
		}

		&:active {
			box-shadow: none;
			transition: none;
		}

		&.mod-ghost {
			background: transparent;
			color: $color-buttons;

			&:hover,
			&:active,
			&:focus {
				color: $color-white;
				background: highlight-by($color-buttons-secondary, 5%);
				box-shadow: 0 0 0 2px highlight-by($color-buttons-secondary, 5%);
			}
		}

		&.mod-link {
			padding: 0;
			background: transparent;
			color: $color-buttons-secondary;
			box-shadow: none;

			& + .btn {
				margin-left: 10px;
			}

			&:hover,
			&:active,
			&:focus {
				color: $color-buttons-secondary;
				background: transparent;
				box-shadow: none;
			}
		}
	}

	&.btn-outline-primary {
		@extend %flex-center;

		width: 120px;
		height: 40px;
		border-radius: 20px;
		color: $color-buttons;
		border: 1px solid $color-buttons;
		font-weight: $font-weight-bold;
		transition: all 0.1s ease;
		padding: 0 12px;

		&:hover,
		&:focus {
			border-color: highlight-by($color-buttons, 5%);
		}

		&:disabled, &[disabled] {
			border-color: $color-text !important;
			box-shadow: 0 0 0 2px $color-text !important;
			color: $color-borders-light !important;
			border: 1px solid $color-text;
		}

		&:active {
			box-shadow: none;
			transition: none;
		}

		&.mod-ghost {
			background: transparent;
			color: $color-buttons;

			&:hover,
			&:active,
			&:focus {
				background: highlight-by($color-buttons, 5%);
				box-shadow: 0 0 0 2px highlight-by($color-buttons, 5%);
			}
		}

		&.mod-link {
			padding: 0;
			color: $color-buttons;
			box-shadow: none;

			& + .btn {
				margin-left: 10px;
			}

			&:hover,
			&:active,
			&:focus {
				color: $color-buttons;
				background: transparent;
				box-shadow: none;
			}
		}
	}

	&.btn-outline-secondary {
		@extend %flex-center;

		width: 120px;
		height: 40px;
		border-radius: 20px;
		color: $color-buttons-secondary;
		border: 1px solid $color-buttons-secondary;
		font-weight: $font-weight-bold;
		transition: all 0.1s ease;
		padding: 0 12px;

		&:hover,
		&:focus {
			border-color: highlight-by($color-buttons-secondary, 5%);
		}

		&:disabled, &[disabled] {
			border-color: $color-text !important;
			box-shadow: 0 0 0 2px $color-text !important;
			color: $color-borders-light !important;
			border: 1px solid $color-text;
		}

		&:active {
			box-shadow: none;
			transition: none;
		}

		&.mod-ghost {
			background: transparent;
			color: $color-buttons-secondary;

			&:hover,
			&:active,
			&:focus {
				background: highlight-by($color-buttons-secondary, 5%);
				box-shadow: 0 0 0 2px highlight-by($color-buttons-secondary, 5%);
			}
		}

		&.mod-link {
			padding: 0;
			color: $color-buttons-secondary;
			box-shadow: none;

			& + .btn {
				margin-left: 10px;
			}

			&:hover,
			&:active,
			&:focus {
				color: $color-buttons-secondary;
				background: transparent;
				box-shadow: none;
			}
		}
	}

	&.btn-icon {
		padding: 12px;
		border: none;
		display: inline-block;
		font-size: $font-size-med;
		background: none;
	}
}
