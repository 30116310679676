.section-content-and-quote {
	@extend %container-large;

	@extend %flex-space-between;

	align-items: flex-start;
	margin: 80px auto 26px;

	.sub-content {
		width: 60%;
		font-size: $font-size-larger;
		padding-right: 50px;
		line-height: 42px;

		h2 {
			margin-bottom: 28px;

			@include mq(tablet-down) {
				font-size: $font-size-larger;
			}
		}

		p {
			@include mq(tablet-down) {
				color: $color-medium-dark-grey;
			}
		}

		@include mq(tablet-down) {
			@include container-large;

			width: 100%;
			padding-right: 0;
			text-align: center;
			font-size: $font-size-small-med;
			line-height: 24px;
			margin-bottom: 26px;
		}
	}

	.sub-quote {
		@extend %flex-column;

		$padding: 32px;

		width: 36%;

		.quote-slides-container {
			padding: 32px;
			margin-bottom: 0;
			border-radius: $padding;
			background: $color-light-grey;

			p {
				margin-bottom: 12px;
				line-height: 39px;
				font-size: $font-size-larger-larger;

				@include mq(tablet-down) {
					font-size: $font-size-large;
					line-height: 31px;
					margin-bottom: 22px;
				}
			}

			strong {
				font-size: $font-size-med;
				font-weight: $font-weight-bold;
				margin-bottom: 64px;
				display: block;

				@include mq(tablet-down) {
					font-size: $font-size-standard;
					margin-bottom: 32px;
				}
			}

			@include mq(tablet-down) {
				min-height: 333px;
				order: 2;
				margin-top: 6px;
			}
		}

		.slick-dots {
			bottom: 24px;
			left: $padding;

			li {
				margin: 0;
				width: 16px;

				button {
					opacity: 1;
					background: $color-ngage-pink;
				}

				&.slick-active button {
					opacity: .2;
					background: $color-ngage-pink;
				}
			}

			@include mq(tablet-down) {
				bottom: 10px;
			}
		}

		.mod-quote-cta-button {
			justify-content: space-between;
			padding: 0 36px;
			margin-top: 14px;
			height: auto;
			min-height: 51px;
			width: 100%;
			border-radius: 44px;
			font-size: $font-size-larger;

			&:first-of-type {
				margin-top: 46px;
			}

			> span {
				@include flex-space-between;

				width: 100%;
				display: flex;
				align-items: center;
				margin: 6px 0;
			}

			@include mq(tablet-down) {
				font-size: $font-size-med;

				&:first-of-type {
					margin-top: 0;
				}

				&:last-of-type {
					margin-bottom: 24px;
				}
			}
		}

		@include mq(tablet-down) {
			margin-top: 12px;
			width: 100%;
		}
	}

	@include mq(tablet-down) {
		flex-direction: column;
		margin: 32px auto 56px;
	}
}
