.section-large-quote {
	@extend %flex-center;

	height: 719px;
	position: relative;
	margin-top: 92px;

	> img {
		height: 100%;
		width: 100%;
		object-fit: cover;

		@include mq(tablet-down) {
			border-radius: $container-radius-mobile;
		}
	}

	.sub-large-quote-container {
		@extend %flex-center;

		flex-direction: column;
		background: rgba($color-teal, 0.9);
		position: absolute;
		top: 0;
		left: 0;
		height: 100%;
		width: 100%;

		h2 {
			color: $color-white;

			@include mq(tablet-down) {
				font-size: $font-size-larger-larger;
			}
		}

		img {
			width: 91px;
			object-fit: contain;

			@include mq(tablet-down) {
				height: 50px;
			}
		}

		p {
			max-width: 963px;
			margin: 36px 0;
			color: $color-white;
			text-align: center;
			width: 70%;
			font-size: $font-size-larger-larger-larger;

			@include mq(tablet-down) {
				margin-top: 20px;
				margin-bottom: 32px;
				font-size: $font-size-small-med;
				width: 100%;
				padding: 0 24px;
			}


			a {
				color: $color-white;
				font-weight: $font-weight-semibold;
			}
		}

		strong {
			color: $color-white;
			font-size: $font-size-larger;

			@include mq(tablet-down) {
				font-weight: $font-weight-bold;
				font-size: $font-size-med;
			}
		}

		.list-centered-socials-icons {
			@extend %list-default;

			@extend %flex-center;

			$icon-size: 31px;
			$mobile-icon-size: 31px;

			li {
				width: $icon-size;
				margin-right: $icon-size;

				img {
					height: $icon-size;
					width: $icon-size;
					object-fit: contain;

					@include mq(tablet-down) {
						height: $mobile-icon-size;
						width: $mobile-icon-size;
					}
				}

				&:last-of-type {
					margin-right: 0;
				}

				@include mq(tablet-down) {
					width: $mobile-icon-size;
					margin-right: $mobile-icon-size;
				}
			}

			@include mq(tablet-down) {
				margin-top: 24px;
			}
		}

		@include mq(tablet-down) {
			border-radius: $container-radius-mobile;
		}
	}

	@include mq(tablet-down) {
		height: 0;
		min-height: 573px;
		margin: 10px $container-gutter-mobile 62px;
		border-radius: $container-radius-mobile;
	}
}
