#conductive-education {
	@import "section-hero-title";
	@import "section-content";

	.section-content {
		h2 {
			@include mq(tablet-down) {
				margin-bottom: 32px;
			}
		}

		.sub-section-content {
			display: flex;

			.section-content-inner {
				width: 50%;

				p {
					font-size: $font-size-med;
					line-height: 20px;

					@include mq(tablet-down) {
						font-size: $font-size-small-med;
						line-height: 24px;
					}
				}

				ul li {
					font-size: $font-size-med;
					line-height: 25px;
				}

				h3 {
					font-size: 27 / $font-base * 1rem;
					margin-bottom: 12px;
				}

				.list-useful-downloads {
					li {
						color: $color-pink;
						font-size: $font-size-large;

						@include mq(tablet-down) {
							width: 100%;
						}
					}
				}

				.list-difficulties-we-support {
					font-size: $font-size-med;
				}

				.list-empower-parents-by {
					font-size: $font-size-med;
				}

				.list-aims-of-conductive-education {
					font-size: $font-size-med;
				}

				.list-conductive-education-websites {
					@extend %list-default;

					color: $color-pink;
					font-size: $font-size-larger-larger;
					line-height: 39px;

					@include mq(tablet-down) {
						font-size: $font-size-larger;
					}
				}

				h3.header-conductive-education-websites {
					margin-top: 42px;
				}

				&:first-child {
					padding-right: 65px;

					@include mq(tablet-down) {
						padding-right: 0;
					}
				}

				&:last-child {
					padding-left: 65px;

					@include mq(tablet-down) {
						padding-left: 0;
						margin-bottom: 0;
					}
				}

				@include mq(tablet-down) {
					width: 100%;
					margin-bottom: 24px;
				}
			}

			@include mq(tablet-down) {
				flex-direction: column;
			}


			.sub-useful-downloads {
				margin-top: 50px;

				ul li {
					font-size: 23 / $font-base * 1rem;
					line-height: 39px;
				}
			}

			.sub-conductive-education-websites {
				ul {
					@extend %list-default;

					li {
						color: $color-link;
						font-size: 23 / $font-base * 1rem;
						line-height: 39px;
					}
				}
			}
		}

		&:last-of-type {
			padding-bottom: 96px;

			@include mq(tablet-down) {
				padding-bottom: 52px;
			}
		}

		@include mq(tablet-down) {
			padding: 32px 0;
		}


		h3 {
			font-size: $font-size-larger;
		}
	}
}
