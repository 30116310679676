.section-timeline {
	background: $color-light-grey;

	.sub-section-timeline {
		@extend %container-large;

		padding: 64px 0 128px;

		h2 {
			text-align: center;
			font-size: $font-size-h1;

			@include mq(mobile) {
				text-align: left;
				font-size: $font-size-larger-larger;
				margin-bottom: 10px;
			}
		}

		.timeline {
			@extend %flex-space-between;

			$padding-top: 52px;
			$padding-top-mobile: 20px;

			position: relative;
			padding-top: $padding-top;

			.timeline-content {
				width: 100%;
				height: 100%;

				.timeline-card {
					width: calc(50% - 42px);
					padding: 24px;
					background: $color-white;
					border-radius: 20px;
					text-align: right;

					h3 {
						font-weight: $font-weight-regular;
						color: $color-light-blue;
						margin-bottom: 8px;

						@include mq(mobile) {
							text-align: left;
							font-size: $font-size-large;
						}
					}

					strong {
						font-size: $font-size-large;
						margin-bottom: 8px;

						@include mq(mobile) {
							display: block;
							text-align: left;
							font-size: $font-size-med;
							font-weight: $font-weight-light;
						}
					}

					p {
						color: $color-black;
						font-size: $font-size-med;
						line-height: 30px;

						@include mq(mobile) {
							font-size: $font-size-med;
							text-align: left;
						}


						&:last-of-type {
							margin-bottom: 0;

							@include mq(mobile) {
								margin-bottom: 30px;
							}
						}
					}

					&.mod-invisible-timeline-card {
						visibility: hidden;
					}

					&.mod-right-timeline-card {
						margin-left: auto;
						text-align: left;

						@include mq(mobile) {
							text-align: center;
						}
					}

					.js-read-more-button {
						text-align: left;
						cursor: pointer;
						display: none;
						font-size: $font-size-med;

						@include mq(mobile) {
							display: block;
						}
					}

					// A read more button will show this content via JS.
					.timeline-card-content {
						@include mq(mobile) {
							display: none;
						}


						&.mod-timeline-card-content-visible {
							@include mq(mobile) {
								display: block;
							}
						}
					}

					@include mq(mobile) {
						width: 100%;
						text-align: center;
						margin-bottom: 0;
					}
				}

				.timeline-card-arrow {
					display: none;
					margin: 24px 0;

					.pink-chevron-down {
						width: 18px;
						margin: auto;
					}

					&:last-of-type {
						display: none;
					}

					@include mq(mobile) {
						display: block;
					}
				}
			}

			.timeline-bar {
				@extend %flex-center;

				align-items: flex-start;
				position: absolute;
				height: calc(100% - #{$padding-top});
				width: 40px;
				left: calc(50% - 20px);
				top: $padding-top;

				.bar {
					// This will be modified by JS so that the line ends at the top of the last card.
					height: 100%;
					width: 2px;
					background: $color-hairline;
				}

				.top-dot {
					position: absolute;
					top: 0;
					background: $color-hairline;
					width: 34px;
					height: 34px;
					border-radius: 50%;
				}

				.js-dynamic-dot {
					position: absolute;
					height: 20px;
					width: 20px;
					background: $color-hairline;
					border-radius: 50%;

					// This will be modified by JS to line up with the timeline content boxes.
					top: 0;
				}

				@include mq(mobile) {
					display: none;
					top: $padding-top-mobile;
					height: calc(100% - #{$padding-top-mobile});
				}
			}

			@include mq(mobile) {
				padding-top: $padding-top-mobile;
			}
		}

		@include mq(mobile) {
			padding: 32px 0 64px;
		}
	}
}
