/* COLOURS */
$color-black: #000000;
$color-white: #ffffff;

$color-dark-blue: #1e3543;
$color-teal: #27939f;
$color-light-blue: #1aabbc;
$color-light-grey: #f9f9f9;
$color-medium-grey: #949494;
$color-medium-dark-grey: #686868;
$color-medium-darker-grey: #464646;
$color-dark-grey: #303030;
$color-light-pink: #f5cfe2;
$color-pink: #e72687;
$color-ngage-pink: #e61f6b;
$url-safe-color-ngage-pink: "%23e61f6b";
$color-hairline: #e0e0e0;

$color-body: $color-white;

$color-neutral: #F5F2F5;
$color-neutral-dark: #607D8B;

$color-borders: #aaaaaa;
$color-borders-light: #c8c8c8;

$color-text: #424242;
$color-text-light: #959595;

$color-headings: #263238;

$color-error: #ff0000;

$color-primary: $color-light-blue;
$color-secondary: #039BE5;

$color-buttons: $color-light-blue;
$color-buttons-secondary: $color-pink;

$color-link: $color-pink;
$color-link-hover: lighten($color-link, 5%);
$color-link-active: $color-link-hover;

$color-validation-error: #cd0000;
$color-validation-success: #4eb73c;

@import url("https://unpkg.com/accordion-js@3.3.2/dist/accordion.min.css");

@import url("https://cdn.jsdelivr.net/npm/normalize.css@8.0.1/normalize.min.css");

@import url("https://cdnjs.cloudflare.com/ajax/libs/slick-carousel/1.8.1/slick.min.css");

@import url("https://cdnjs.cloudflare.com/ajax/libs/slick-carousel/1.8.1/slick-theme.min.css");

@import url("https://use.typekit.net/lmv6xtc.css");

/* FONTS */
$font-standard: Foco, Arial, Helvetica, Sans-serif;

$font-base: 14;

$font-size-base: $font-base * 1px;
$font-size-standard: 14 / $font-base * 1rem;
$font-size-tinier: 9 / $font-base * 1rem;
$font-size-tiny: 10 / $font-base * 1rem;
$font-size-small: 12 / $font-base * 1rem;
$font-size-small-med: 15 / $font-base * 1rem;
$font-size-med: 16 / $font-base * 1rem;
$font-size-large: 18 / $font-base * 1rem;
$font-size-larger: 21 / $font-base * 1rem;
$font-size-larger-larger: 24 / $font-base * 1rem;
$font-size-larger-larger-larger: 28 / $font-base * 1rem;
$font-size-largest: 30 / $font-base * 1rem;
$font-size-massive: 40 / $font-base * 1rem;

$font-size-h1: 36 / $font-base * 1rem;
$font-size-h2: 34 / $font-base * 1rem;
$font-size-h3: 24 / $font-base * 1rem;
$font-size-h4: $font-size-large;
$font-size-h5: $font-size-med;
$font-size-h6: $font-size-standard;

$font-size-hero-standard: $font-size-larger;
$font-size-hero-small: 20 / $font-base * 1rem;
$font-size-hero-med: 50 / $font-base * 1rem;
$font-size-hero-large: 65 / $font-base * 1rem;

$font-weight-light: 300;
$font-weight-regular: 400;
$font-weight-semibold: 500;
$font-weight-bold: 600;
$font-weight-bolder: 700;

$headings-font: $font-standard;
$headings-font-weight: $font-weight-light;
$headings-color: $color-headings;
$headings-line-height: 1.4;

$line-height-base: 1.55;
$line-height-large: 2;

$radius-button: 5px;
$padding-button: 15px;
$text-size-button: $font-size-standard;

/* STRUCTURE */
$viewport-width: 100%;
$container-width: 95%;
$container-max-width: 1440px;
$container-max-width-small: 900px;
$container-max-width-large: 1316px;

/* MOBILE STRUCTURE */
$container-width-mobile: 100%;
$container-gutter-mobile: 20px;
$container-max-width-mobile: calc(100% - #{$container-gutter-mobile * 2});
$container-radius-mobile: 16px;

/* MEDIA QUERY */
$mq-desktop-size: $container-max-width;
$mq-tablet-size: 1065px;
$mq-mobile-size: 768px;
