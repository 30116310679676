.section-form {
	@extend %flex-center;

	.inner-form {
		background: $color-white;
		width: 787px;
		border-radius: 32px;
		margin: 72px 0 12px;

		h2 {
			margin-bottom: 22px;

			@include mq(tablet-down) {
				font-size: $font-size-larger;
			}
		}

		p {
			font-size: $font-size-larger;
			color: $color-dark-grey;

			@include mq(tablet-down) {
				font-size: $font-size-standard;
			}
		}

		form {
			@extend %flex-column;

			margin-top: 52px;

			input {
				border: 0;
				line-height: 38px;
				font-size: $font-size-med;
				color: $color-medium-dark-grey;

				@include mq(tablet-down) {
					font-size: $font-size-standard;
				}
			}

			.terms {
				margin: 50px 0;

				.term {
					align-items: flex-start;
					display: flex;

					input[type="checkbox"] {
						margin-right: 14px;
						width: 14px;
						margin-bottom: 0;
						line-height: 19px;
						margin-top: 4px;

						@include mq(tablet-down) {
							width: 12px;
						}
					}

					label {
						font-size: $font-size-standard;
						color: $color-medium-grey;

						@include mq(tablet-down) {
							font-size: $font-size-small;
						}
					}
				}
			}

			@include mq(tablet-down) {
				margin-top: 32px;
			}
		}

		.action-button-container {
			@extend %flex-center;

			.mod-hero-btn {
				display: inline-flex;
				width: auto;
				height: 51px;
				border-radius: 26px;
				font-size: $font-size-larger;
				margin-top: 12px;

				> span {
					margin: 0 92px;

					@include mq(tablet-down) {
						margin: 0 42px;
					}
				}

				@include mq(tablet-down) {
					margin-top: 24px;
					height: 36px;
					font-size: $font-size-small-med;
				}
			}
		}

		.close-download-form-btn {
			position: absolute;
			top: 24px;
			right: 24px;
			width: 32px;
			height: 32px;
			cursor: pointer;

			img {
				width: 100%;
				height: 100%;
			}

			@include mq(tablet-down) {
				width: 14px;
				height: 14px;
			}
		}

		@include mq(tablet-down) {
			height: auto;
			width: auto;
			padding: 0 24px;
			margin-bottom: 64px;
		}
	}

	&.mod-download-form-section-hidden {
		display: none;
	}

	.sub-errors {
		margin-bottom: 20px;

		p {
			color: $color-error;
			font-size: $font-size-med;
		}

		span {
			font-size: $font-size-small-med;
		}
	}

	.sub-thanks {
		font-size: $font-size-large;
		display: none;
		padding-top: 35px;
	}
}
