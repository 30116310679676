.section-news-cards {
	@extend %container-large;

	h2 {
		text-align: center;
		margin: 91px auto 62px;

		@include mq(tablet-down) {
			margin-top: 0;
			margin-bottom: 36px;
			font-size: $font-size-h3;
		}
	}

	.sub-news-cards {
		@extend %list-default;

		display: flex;
		flex-wrap: wrap;

		.news-card {
			@extend %flex-column;

			$border-radius: 19px;

			// A third of the screen's width minus some more for "padding".
			width: $container-max-width-large / 3 - 20px;
			height: 495px;
			margin-bottom: 51px;
			margin-right: 30px;
			background: $color-light-grey;
			border-radius: $border-radius;

			&:nth-child(3n) {
				margin-right: 0;
			}

			img {
				width: 100%;
				height: 240px;
				object-fit: cover;
				border-radius: $border-radius $border-radius 0 0;

				@include mq(tablet-down) {
					height: 157px;
				}
			}

			.news-card-content {
				@extend %flex-column;

				@extend %flex-grow;

				padding: 20px;

				.news-card-date {
					color: $color-medium-grey;
					font-size: $font-size-med;

					@include mq(tablet-down) {
						font-size: $font-size-small;
					}
				}

				h3 {
					margin-top: 8px;
					padding-right: 40px;

					@include mq(tablet-down) {
						font-size: $font-size-large;
					}
				}

				.mod-news-card-btn {
					height: 36px;
					margin-top: auto;
					margin-bottom: 6px;
				}
			}

			@include mq(tablet-down) {
				height: 370px;
				width: 50px;
				border-radius: $container-radius-mobile;
				margin: 0 auto 8px;
			}


			@media screen and (max-width: 1310px) {
				width: calc(50% - 24px);
				max-width: calc(50% - 24px);
			}

			@media screen and (max-width: 1030px) {
				width: 100%;
				max-width: 100%;
			}
		}

		@include mq(tablet-down) {
			flex-direction: column;
		}
	}

	.sub-view-more-button-container {
		@extend %flex-center;

		height: 170px;
		margin-top: -24px;
		margin-bottom: 32px;

		> .btn {
			border-radius: 26px;
			font-size: $font-size-larger;
			height: 51px;
			max-width: 192px;
			width: 100%;

			> span {
				margin: 0 48px;

				@include mq(tablet-down) {
					margin: 0 24px;
				}
			}

			@include mq(tablet-down) {
				height: 36px;
				font-size: $font-size-med;
			}
		}

		@include mq(tablet-down) {
			margin-top: 0;
			height: 100px;
		}
	}

	@include mq(tablet-down) {
		margin-top: 0;
		margin-bottom: 0;
	}
}
