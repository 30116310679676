#events {
	@import "section-hero-title";
	@import "section-large-quote";
	@import "section-content-and-box";
	@import "section-content";
	@import "section-hero-with-links";

	.section-content-and-box .sub-box .box {
		background: unset;
		padding: 0;

		img {
			display: block;
			margin: auto;
			max-width: 100%;
		}
	}

	.section-content {
		background: $color-light-grey;
		margin-top: 128px;
		padding: 62px 0 128px;

		.section-content-inner {
			@extend %flex-row;

			.list-past-and-current-events {
				@extend %list-default;

				width: 50%;

				li {
					margin-bottom: 36px;

					strong {
						font-weight: $font-weight-light;
						color: $color-pink;
						font-size: $font-size-large;
						line-height: 30px;
						margin-bottom: 0;

						@include mq(tablet-down) {
							display: block;
							width: 100%;
							text-align: center;
							font-size: $font-size-small-med;
							line-height: 24px;
						}
					}

					p {
						font-size: $font-size-large;
						line-height: 30px;

						@include mq(tablet-down) {
							font-size: $font-size-small-med;
							line-height: 24px;
						}
					}
				}

				&:nth-child(1) {
					padding-right: 48px;

					@include mq(tablet-down) {
						padding-right: 0;
					}
				}

				&:nth-child(2) {
					padding-left: 48px;

					@include mq(tablet-down) {
						padding-left: 0;
					}
				}

				@include mq(tablet-down) {
					width: 100%;
				}
			}

			@include mq(tablet-down) {
				flex-direction: column;
			}
		}

		@include mq(tablet-down) {
			margin-top: 72px;
			padding-bottom: 32px;
		}
	}

	.section-large-quote {
		min-height: 431px;
		height: 431px;
		margin-top: 0;
	}
}
