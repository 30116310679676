.section-download-form {
	@extend %flex-center;

	position: fixed;
	top: 0;
	left: 0;
	height: 100vh;
	width: 100vw;
	z-index: 1000;
	background: $color-dark-grey;

	.inner-download-form {
		position: relative;
		background: $color-white;
		width: 787px;
		height: 765px;
		border-radius: 32px;
		overflow: scroll;
		padding: 52px;

		h2 {
			margin-bottom: 22px;

			@include mq(tablet-down) {
				font-size: $font-size-larger;
			}
		}

		p.sub-title {
			font-size: $font-size-larger;
			color: $color-dark-grey;

			@include mq(mobile) {
				display: none;
			}
		}

		form {
			@extend %flex-column;

			margin-top: 22px;

			input {
				border: 0;
				line-height: 38px;
				font-size: $font-size-med;
				color: $color-medium-dark-grey;

				@include mq(tablet-down) {
					font-size: $font-size-standard;
				}
			}

			.terms {
				& > p {
					font-size: $font-size-med;
					padding-right: 50px;

					@include mq(tablet-down) {
						font-size: $font-size-small-med;
						padding-right: 0;
					}
				}

				h4 {
					font-weight: $font-weight-semibold;
					font-size: $font-size-med;
				}

				.term {
					margin-bottom: 24px;

					input[type="checkbox"] {
						margin-right: 5px;
						width: 14px;
						margin-bottom: 0;
						line-height: 19px;

						@include mq(tablet-down) {
							width: 12px;
						}
					}

					label {
						font-size: $font-size-standard;
						color: $color-medium-dark-grey;
						margin-right: 20px;

						@include mq(tablet-down) {
							font-size: $font-size-small;
							color: $color-medium-grey;
						}
					}
				}
			}

			.checkboxes {
				label {
					font-size: $font-size-med;
				}

				.list-checkboxes {
					@extend %list-default;

					border-bottom: 1px solid $color-borders;
					padding-bottom: 40px;
					margin: 20px 0 40px;

					& > li {
						&:not(:last-of-type) {
							margin-bottom: 10px;
						}

						input {
							margin-right: 10px;
						}
					}
				}
			}

			@include mq(tablet-down) {
				margin-top: 32px;
			}
		}

		.donation-box {
			border: 1px solid $color-borders;
			height: 30px;
			font-weight: $font-weight-regular;
			margin-left: 8px;
			text-align: center;
			width: 30px;

			@include mq(tablet-down) {
				height: 27px;
				font-size: $font-size-standard;
				width: 27px;
			}
		}

		.download-now-button-container {
			@extend %flex-center;

			.mod-hero-btn {
				display: inline-flex;
				width: auto;
				height: 51px;
				border-radius: 26px;
				font-size: $font-size-larger;

				> span {
					margin: 0 56px;

					@include mq(tablet-down) {
						margin: 0 42px;
					}
				}

				@include mq(tablet-down) {
					margin-top: 24px;
					height: 36px;
					font-size: $font-size-small-med;
				}
			}
		}

		.close-download-form-btn {
			position: absolute;
			top: 24px;
			right: 24px;
			width: 32px;
			height: 32px;
			cursor: pointer;

			img {
				width: 100%;
				height: 100%;
			}

			@include mq(tablet-down) {
				width: 14px;
				height: 14px;
			}
		}

		@include mq(tablet-down) {
			height: 75vh;
			overflow: scroll;
			padding: 20px;
			width: auto;
		}
	}

	&.mod-download-form-section-hidden {
		display: none;
	}

	@include mq(tablet-down) {
		padding: 16px;
	}

	.sub-errors {
		&.mod-active {
			margin-bottom: 20px;
		}

		p {
			color: $color-error;
			font-size: $font-size-med;
		}

		span {
			font-size: $font-size-small-med;
		}
	}

	.sub-thanks {
		font-size: $font-size-large;
		display: none;
		padding-top: 35px;
	}
}
