.section-read-more-content-modal {
	@extend %flex-center;

	position: fixed;
	top: 0;
	left: 0;
	height: 100vh;
	width: 100vw;
	z-index: 1000;
	background: $color-dark-grey;

	.sub-read-more-content {
		position: relative;
		background: $color-white;
		width: 787px;
		height: 765px;
		border-radius: 32px;
		padding: 52px;
		overflow: auto;

		h2 {
			margin-bottom: 22px;

			@include mq(tablet-down) {
				font-size: $font-size-larger;
			}
		}

		strong {
			display: block;
			font-size: $font-size-h2;
			margin-bottom: 22px;

			@include mq(tablet-down) {
				font-size: $font-size-larger;
			}
		}

		p {
			font-size: $font-size-larger;
			color: $color-dark-grey;

			@include mq(tablet-down) {
				font-size: $font-size-small-med;
				line-height: 24px;
			}
		}

		.close-read-more-content-modal-btn {
			cursor: pointer;
			height: 32px;
			position: absolute;
			right: 20px;
			top: 24px;
			width: 25px;

			img {
				width: 100%;
				height: 100%;
			}

			@include mq(tablet-down) {
				width: 14px;
				height: 14px;
			}
		}

		@include mq(tablet-down) {
			height: 85vh;
			width: 100%;
			padding: 52px 24px;
		}
	}

	&.mod-read-more-content-modal-hidden {
		display: none;
	}

	.mod-hidden-card-content {
		display: block;
	}

	@include mq(tablet-down) {
		padding: 16px;
	}
}

.js-read-more-button {
	cursor: pointer;
}
