#our-supporters {
  @import "section-hero-title";
  @import "section-content-and-box";
  @import "section-large-quote";
  @import "section-content";
  @import "section-hero-with-links";
  @import "partial-how-x-can-help";

  .section-content-and-box .sub-box .box {
    background: unset;
    padding: 0;

    img {
      display: block;
      margin: auto;
      max-width: 100%;
    }
  }

  .section-content {
    background: $color-light-grey;
  }

  .section-large-quote {
    margin-top: 0;
  }

  .section-content .sub-section-content {
    column-count: 2;

    p {
      font-size: $font-size-large;
      line-height: 30px;
      margin: 0;

      @include mq(tablet-down) {
        font-size: $font-size-small-med;
        line-height: 24px;
      }
    }
  }
}
