.section-content {
  padding: 62px 0;

  h2 {
    @extend %container-large;

    text-align: center;
    margin-bottom: 42px;
    column-span: all;

    @include mq(tablet-down) {
      font-size: $font-size-larger;
    }

    &.mod-section-content-title-left-aligned {
      text-align: left;

      @include mq(tablet-down) {
        text-align: center;
      }
    }
  }

  .sub-section-content {
    @extend %container-large;

    strong {
      display: block;
      font-size: $font-size-large;
      font-weight: $font-weight-light;
      margin-bottom: 18px;

      &:not(:first-of-type) {
        margin-top: 42px;
      }

      @include mq(tablet-down) {
        text-align: center;
      }
    }

    p {
      font-size: $font-size-larger;
      line-height: 42px;

      @include mq(tablet-down) {
        font-size: $font-size-small-med;
        line-height: 24px;
        text-align: center;
      }
    }
  }

  &.mod-section-content-dark-background {
    background: $color-light-grey;
  }
}
