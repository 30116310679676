.footer-main {
	display: flex;
	background: $color-dark-blue;
	z-index: 1;

	.footer-cta-button-container {
		display: flex;
		align-items: flex-start;

		.mod-footer-cta-btn {
			font-size: $font-size-large;
			border-radius: 26px;
			height: 45px;
			width: 128px;
			margin-right: 15px;
		}
	}

	.footer-socials {
		img {
			width: 25px;
			height: 25px;
			object-fit: contain;
			margin-right: 30px;
		}
	}

	.footer-contact-details {
		.list-footer-contact-details {
			@extend %list-default;

			display: flex;

			.footer-contact-details-telephone, .footer-contact-details-email {
				font-size: $font-size-h3;
				margin-right: 20px;
				color: #1aa6b7;

				a {
					color: $color-white;
				}
			}
		}

		.footer-contact-details-address {
			color: $color-white;
			font-size: $font-size-small;
			opacity: 0.6;
		}
	}

	.footer-copyright {
		font-size: $font-size-tiny;
		color: $color-hairline;
		display: flex;
		align-items: center;
		opacity: 0.6;
	}

	.footer-left-logo {
		display: flex;

		img {
			display: block;
			margin: auto 0;
			margin-right: 20px;
		}
	}

	ul {
		@extend %list-default;

		display: flex;
	}

	.footer-nav {
		color: $color-white;
		font-size: $font-size-large;
		font-weight: $font-weight-bold;

		ul {
			@extend %flex-space-between;

			margin-top: 12px;

			li a {
				color: $color-white;
			}
		}
	}

	.footer-legal-links {
		@extend %flex-end;

		opacity: 0.6;
		color: $color-hairline;
		font-size: $font-size-tiny;

		ul {
			display: flex;
			justify-content: flex-end;

			@include mq(mobile) {
				justify-content: flex-start;
			}


			li {
				&.footer-legal-link-vertical-spacer {
					width: 38px;
				}

				text-align: center;

				a {
					color: $color-hairline;
					font-size: $font-size-tiny;
				}
			}
		}
	}

	.footer-small-print {
		color: $color-hairline;
		font-size: $font-size-tiny;
		text-align: right;
		opacity: 0.6;

		.footer-charity-number {
			margin-top: 12px;
		}

		span:last-child {
			margin-left: 28px;

			@include mq(tablet-down) {
				margin-left: 0;
			}
		}
	}
}

.footer-main-desktop {
	height: 365px;
	padding: 38px 0 22px 0;

	.sub-footer {
		@extend %flex-center;

		@extend %container-large;

		.sub-footer-contact-details, .sub-footer-navigation {
			@extend %flex-column;

			width: 50%;
			height: 100%;

			> div {
				&:nth-child(1) {
					height: 86px;
				}

				&:nth-child(2) {
					height: 50px;
				}

				&:nth-child(3) {
					height: 90px;
				}

				&:nth-child(4) {
					height: 62px;
				}

				&:nth-child(5) {
					height: 46px;
				}
			}
		}

		.sub-footer-navigation {
			.footer-right-logo {
				display: block;
				margin-left: auto;

				img {
					height: 69px;
				}
			}
		}
	}
}

.footer-main-mobile {
	display: none;
}

@media only screen and (max-width: 1125px) {
	.footer-main-desktop {
		display: none;
	}

	.footer-main-mobile {
		display: flex;
		flex-direction: column;
		padding: 32px $container-gutter-mobile 16px;

		.footer-cta-button-container {
			margin-bottom: 24px;

			.mod-footer-cta-btn {
				height: 35px;
				font-size: $font-size-small;
				width: 108px;
			}
		}

		.footer-contact-details {
			.list-footer-contact-details {
				flex-direction: column;
				margin-bottom: 6px;

				.footer-contact-details-icon {
					display: inline-block;
					width: 1ch;
				}

				li.footer-contact-details-telephone,
				li.footer-contact-details-email {
					font-size: $font-size-standard;

					a {
						margin-left: 2px;
					}
				}
			}

			.footer-contact-details-address {
				font-size: $font-size-tiny;
			}
		}

		.footer-nav {
			margin-top: 36px;

			ul {
				flex-direction: column;
				align-items: start;

				li {
					font-size: $font-size-small;
					margin-bottom: 14px;

					// Contains the socials logos.
					&:last-child {
						display: flex;
						justify-content: space-between;
						width: 100%;

						> a {
							// Centre of logos height.
							margin-top: 3px;
						}

						a {
							img {
								height: 20px;
								width: 20px;

								// Visually center the Facebook logo.
								object-position: right;
								margin-right: 16px;
							}

							&:last-child {
								img {
									margin: 0;
								}
							}
						}
					}
				}
			}
		}

		.footer-left-logo.footer-right-logo {
			justify-content: space-between;
			margin-top: 24px;

			@include mq(mobile) {
				align-items: flex-start;
				flex-direction: column;
			}


			.sub-logos {
				display: flex;

				@include mq(mobile) {
					margin-top: 30px;
				}
			}

			img {
				height: 33px;

				@include mq(mobile) {
					height: 40px;
				}


				&:last-of-type {
					margin-right: 0;
				}
			}
		}

		.footer-legal-links {
			margin-top: 28px;
			opacity: 0.6;

			ul li a {
				font-size: $font-size-tinier;
			}
		}

		.footer-copyright {
			margin-top: 24px;
			font-size: $font-size-tinier;
		}

		.footer-small-print {
			text-align: left;
			font-size: $font-size-tinier;

			.footer-charity-number {
				margin-top: 0;
				display: flex;
				flex-direction: column;
			}
		}
	}
}
