#leave-a-gift-in-your-will {
	@import "section-hero-image";
	@import "section-content-and-quote";

	.section-hero-image {
		position: relative;

		.hero-image-text-container {
			z-index: 99;
		}

		.hero-image-overlay {
			background: linear-gradient(270deg, hsla(0, 0%, 100%, 0), hsla(0, 0%, 100%, 0.94) 75%, #fff);
			content: "";
			height: 100%;
			left: 0;
			position: absolute;
			top: 0;
			width: 100%;
			z-index: 1;
		}

		.mod-hero-btn {
			margin-top: 18px;
			width: unset;
			height: 51px;
			font-size: $font-size-larger;
			padding: 0 28px;
			border-radius: 30px;
		}
	}

	.section-content-and-quote {
		padding-bottom: 150px;

		.sub-content {
			width: 90%;

			@include mq(mobile) {
				width: 100%;
			}
		}
	}
}
