#how-charitable-trusts-and-foundations-can-help {
	@import "section-hero-title";
	@import "section-content-and-quote";
	@import "section-full-width-cta";
	@import "section-hero-with-links";
	@import "section-large-quote";
	@import "partial-how-x-can-help";
	@import "_section-video-modal";

	.section-hero-title {
		.sub-section-hero-title {
			.section-hero-title-inner h1 {
				@include mq(tablet-down) {
					max-width: 300px;
				}
			}
		}
	}

	.section-content-and-quote {
		.sub-content {
			img {
				height: 183px;
				max-width: 100%;
				margin-top: 72px;
				object-fit: contain;

				@include mq(tablet-down) {
					margin-top: 0;
				}
			}
		}
	}

	.section-full-width-cta {
		height: 105px;
		justify-content: flex-start;
		margin-top: 50px;
		padding: 0 30px;

		h2 {
			font-size: $font-size-largest;
			flex: 1;
		}
	}
}
