.section-full-width-cta {
	@extend %flex-center;

	@extend %container-large;

	background: $color-light-grey;
	height: 150px;
	border-radius: 32px;
	margin-bottom: 31px;

	.mod-hero-btn {
		margin: 0 0 0 36px;
		min-width: 156px;
		width: unset;
		height: 51px;
		font-size: 1.5rem;
		padding: 0 28px;
		border-radius: 30px;

		@include mq(tablet-down) {
			min-width: 110px;
			height: 36px;
			padding: 0;
			margin: 0;
			font-size: $font-size-med;
		}
	}

	@include mq(tablet-down) {
		flex-direction: column;
		margin-left: $container-gutter-mobile;
		margin-right: $container-gutter-mobile;

		h2 {
			font-size: $font-size-larger;
			text-align: center;
			max-width: 250px;
			margin-bottom: 14px;
		}
	}
}
