.section-hero-with-links {
  position: relative;
  min-height: 502px;

  .sub-hero-with-links-content-container, > img {
    position: absolute;
    left: 0;
    top: 0;
    height: 100%;
    width: 100%;
  }

  .sub-hero-with-links-content-container {
    z-index: 1;
    padding: 97px 0;

    .hero-with-links-content {
      @extend %container-large;

      h2 {
        margin-bottom: 42px;

        @include mq(tablet-down) {
          margin-bottom: 28px;
          font-size: $font-size-larger-larger;
          max-width: 200px;
        }
      }

      ul {
        @extend %list-default;

        .pink-chevron-down {
          transform: rotate(270deg);
        }

        .btn-outline-secondary {
          @extend %flex-space-between;

          height: 51px;
          width: 475px;
          border-radius: 44px;
          font-size: $font-size-larger;
          padding: 0 36px;
          margin: 23px 0;

          .pink-chevron-down {
            width: 14px;
          }

          @include mq(tablet-down) {
            height: 40px;
            width: 221px;
            font-size: $font-size-small-med;
            padding: 0 16px;
          }
        }
      }
    }

    @include mq(tablet-down) {
      padding: 55px 0;
    }
  }

  > img {
    object-fit: cover;
  }

  @include mq(tablet-down) {
    min-height: 393px;
  }
}
