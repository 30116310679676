.section-image-cards-container {
	.section-image-cards {
		@extend %container-large;

		padding: 66px 0 42px;

		h2, > p {
			margin: auto;
			max-width: 1040px;
			text-align: center;
		}

		h2 {
			font-size: $font-size-h1;
			margin-bottom: 16px;

			@include mq(tablet-down) {
				font-size: $font-size-larger;
				margin-bottom: 8px;
			}
		}

		> p {
			font-size: $font-size-larger;
			line-height: 42px;
			color: $color-dark-grey;
			margin-bottom: 68px;

			@include mq(tablet-down) {
				font-size: $font-size-small-med;
				line-height: 24px;
				margin-bottom: 48px;
			}
		}

		.list-cards-container {
			@extend %flex-center;

			.list-cards {
				@extend %list-default;

				@extend %flex-wrap;

				li {
					width: 230px;
					height: auto;
					margin-right: 33px;
					margin-bottom: 52px;

					img {
						border-radius: 36px;
						height: 280px;
						width: 100%;
						object-fit: cover;
						margin-bottom: 16px;
					}

					strong {
						font-size: $font-size-larger;

						@include mq(tablet-down) {
							font-size: $font-size-large;
						}
					}

					.sub-title {
						min-height: 55px;
						margin-bottom: 8px;
					}

					h3 {
						color: $color-medium-dark-grey;
						font-size: $font-size-med;
						font-weight: $font-weight-regular;
						margin-bottom: 0;
					}

					p, a.js-member-read-more-button {
						font-size: $font-size-small-med;
						line-height: 27px;
						margin: 0;
					}

					p {
						color: $color-medium-dark-grey;
					}

					a.js-member-read-more-button {
						cursor: pointer;
					}

					@include mq(tablet-down) {
						margin-right: 0;
						margin-bottom: 24px;
					}
				}

				@include mq(tablet-down) {
					flex-direction: column;
				}


				@media screen and (max-width: 1200px) {
					@include flex-center;
				}
			}

			&.mod-left-aligned {
				justify-content: flex-start;

				@media screen and (max-width: 1200px) {
					justify-content: center;
				}
			}
		}

		@include mq(tablet-down) {
			padding: 0;
		}
	}

	&.mod-light-grey-background-section-image-cards-container {
		background: $color-light-grey;
	}

	&.mod-no-paragraph {
		h2 {
			margin-bottom: 68px;

			@include mq(tablet-down) {
				margin-bottom: 16px;
			}
		}
	}

	.mod-hidden-card-content {
		display: none;
	}

	@include mq(tablet-down) {
		padding: 30px 0 0;
	}
}
