.section-accordion {
	margin: 48px 0 100px;

	h2 {
		@extend %container-large;

		margin-bottom: 36px;

		@include mq(tablet-down) {
			font-size: $font-size-larger-larger;
			margin-bottom: 28px;
		}
	}

	ul.sub-accordion {
		@extend %container-large;

		@extend %list-default;

		margin: auto;

		.accordion-item {
			background: $color-light-grey;
			border-radius: 20px;
			margin-bottom: 6px;

			.accordion-header {
				@extend %flex-space-between;

				background: none;
				color: inherit;
				border: none;
				padding: 26px;
				font: inherit;
				outline: inherit;
				cursor: pointer;
				width: 100%;

				h3 {
					font-size: $font-size-large;
					font-weight: $font-weight-light;
					color: $color-black;
					margin: 0;
					display: block;
					line-height: 25px;
					max-width: 954px;

					@include mq(tablet-down) {
						max-width: 504px;
					}


					@include mq(mobile) {
						max-width: 204px;
					}
				}

				.pink-chevron-down {
					transition: 0.3s;
					transform: rotate(360deg);
				}
			}

			.accordion-content {
				font-size: $font-size-med;
				line-height: 30px;
				padding: 0 26px 26px 26px;

				p {
					margin: 0;
					color: $color-black;
					padding-top: 13px;

					@include mq(tablet-down) {
						padding-top: 20px;
					}
				}
			}

			&.mod-accordion-item-closed {
				.accordion-header {
					.pink-chevron-down {
						transform: rotate(270deg);
					}
				}
			}

			@include mq(tablet-down) {
				margin-bottom: 12px;
			}
		}
	}

	@include mq(tablet-down) {
		margin: 24px 0 52px;
	}
}
