#how-you-can-help {
	@import "section-hero-title";
	@import "section-full-width-cta";
	@import "section-hero-with-links";
	@import "section-content-and-box";
	@import "section-two-column-content";
	@import "_section-video-modal";

	.section-content-and-box .sub-box .box {
		h2 {
			font-size: $font-size-larger-larger-larger;

			@include mq(mobile) {
				font-size: $font-size-large;
			}
		}

		p {
			font-size: $font-size-larger;

			@include mq(mobile) {
				font-size: $font-size-med;
				line-height: 31px;
			}
		}
	}

	.section-donation-info-cards {
		$sub-header-container-height: 177px;
		$mobile-sub-header-container-height: 100px;
		$mobile-border-radius: 19px;

		@extend %flex-center;

		flex-direction: column;
		height: 712px;
		display: flex;
		position: relative;
		margin-top: 92px;

		> img {
			height: 100%;
			width: 100%;
			object-fit: cover;
			position: absolute;
			left: 0;
			top: 0;

			@include mq(tablet-down) {
				border-radius: $mobile-border-radius;
			}
		}

		.sub-header-container {
			@extend %flex-center;

			z-index: 1;
			width: 100%;
			height: $sub-header-container-height;
			background: rgba($color-teal, 0.9);

			h2 {
				color: $color-white;

				@include mq(tablet-down) {
					text-align: center;
					max-width: 230px;
					font-size: $font-size-larger-larger;
					margin-top: 14px;
				}
			}

			@include mq(tablet-down) {
				height: $mobile-sub-header-container-height;
				border-radius: $mobile-border-radius $mobile-border-radius 0 0;
			}
		}

		.sub-donation-info-cards {
			background: rgba($color-teal, 0.9);
			height: 100%;
			width: 100%;
			z-index: 1;

			.donation-info-cards-inner {
				$padding: 12px;

				@extend %container-large;

				display: flex;
				height: calc(100% - 80px);

				.main-card {
					width: 45%;
					height: 100%;

					.card {
						height: 100%;
						padding: 0 $padding 0 0;

						.card-content {
							position: relative;
							font-size: $font-size-larger-larger;

							.card-icon {
								display: flex;
								align-items: flex-end;
								position: absolute;
								bottom: 28px;
								left: 28px;
								height: 100%;
								border-radius: 20px;

								@include mq(tablet-down) {
									top: 12px;
									right: 12px;
									align-items: flex-start;
									justify-content: flex-end;

									> img {
										height: 45px;
									}
								}
							}
						}

						@include mq(tablet-down) {
							padding: 0;
						}
					}
				}

				.stacked-cards {
					display: flex;
					flex-wrap: wrap;
					width: 100%;

					.card {
						position: relative;
						padding: $padding 0 $padding $padding;

						.card-content {
							font-size: $font-size-larger;
						}

						&:nth-child(1) {
							padding-top: 0;
							height: 31%;
							width: 100%;

							.card-icon {
								@extend %flex-center;

								position: absolute;
								top: 0;
								right: 24px;
								height: calc(100% - #{$padding});
								border-radius: 20px;

								@include mq(tablet-down) {
									top: 12px;
									right: 12px;
									align-items: flex-start;
									justify-content: flex-end;

									> img {
										height: 45px;
									}
								}
							}
						}

						&:nth-child(2) {
							height: 47.5%;
							width: 50%;
							padding-right: $padding / 2;
						}

						&:nth-child(3) {
							height: 47.5%;
							width: 50%;

							// It's not pretty but it works.
							padding-left: $padding + $padding / 2;
						}

						&:nth-child(4) {
							height: 24%;
							width: 100%;

							.card-content {
								font-size: $font-size-larger-larger;

								@include mq(tablet-down) {
									font-size: $font-size-small-med;
								}
							}
						}

						@include mq(tablet-down) {
							// !important overrides the :nth-child specificity above.
							width: 100% !important;
							padding: 0 !important;
						}
					}
				}

				.main-card, .stacked-cards {
					.card {
						.card-content {
							background: rgba($color-white, 0.1);
							height: 100%;
							border-radius: 20px;
							color: $color-white;
							padding: 0 18px;

							.card-price {
								color: $color-white;
								font-size: 56 / $font-base * 1rem;
								font-weight: $font-weight-bold;
								display: inline-block;
								margin-bottom: -10px;

								&.mod-block-card-price {
									display: block;
								}

								@include mq(tablet-down) {
									display: block;
									font-size: $font-size-massive;
								}
							}

							p {
								display: inline-block;
								margin: 0;

								@include mq(tablet-down) {
									max-width: 80%;
								}
							}

							@include mq(tablet-down) {
								font-size: $font-size-small-med;
								padding-bottom: 12px;
							}
						}

						@include mq(tablet-down) {
							margin-bottom: 14px;
						}
					}

					@include mq(tablet-down) {
						width: 100%;
					}
				}

				@include mq(tablet-down) {
					height: auto;
					flex-direction: column;
				}
			}

			@include mq(tablet-down) {
				border-radius: 0 0 $mobile-border-radius $mobile-border-radius;
				height: auto;
				padding-top: 18px;
				padding-bottom: 18px;
			}
		}

		@include mq(tablet-down) {
			height: auto;
			min-height: 573px;
			margin: 10px $container-gutter-mobile 62px;
		}
	}

	.section-full-width-cta {
		margin-top: 48px;
		margin-bottom: 48px;
	}

	.sub-large-text p {
		font-size: (27 / $font-base * 1rem) !important;
	}
}
