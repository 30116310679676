$header-height: 106px;
$mobile-header-height: 61px;

html {
	font-size: $font-size-base;

	body {
		padding-top: $header-height;
		line-height: $line-height-base;
		font-weight: $font-weight-light;
		font-family: $font-standard;
		background: $color-body;
		color: $color-text;
		font-size: $font-size-standard;
		overflow-x: hidden;
		margin: auto;

		a.anchor {
			display: block;
			visibility: hidden;
			position: relative;
			top: -106px; // Usually height of the header
	
		}

		@include mq(tablet-down) {
			padding-top: $mobile-header-height;
		}
	}

	* {
		box-sizing: border-box;
	}

	.sub-content {
		table {
			margin: 35px 0;

			td {
				padding: 6px;
				vertical-align: middle;

				p {
					margin-bottom: 0;
				}
			}
		}

		hr {
			background-color: $color-borders;
			border: none;
			height: 1px;
			margin: 40px 0;
		}
	}

	.grecaptcha-badge {
		display: none !important;
	}
}
