#our-history {
	@import "section-hero-title";
	@import "section-content";
	@import "section-hero-with-links";
	@import "section-timeline";

	.section-content {
		@include mq(tablet-down) {
			padding: 32px 0;
		}


		p:last-of-type {
			@include mq(tablet-down) {
				margin: 0;
			}
		}
	}
}
