.section-links-with-images {
	@extend %flex-center;

	@extend %container-large;

	$padding: 12px;

	@media only screen and (min-width: $mq-tablet-size + 1) {
		position: relative;
		height: 680px;
		margin-top: 31px;

		// Offset the padding so that the container content lines up to 1300px.
		max-width: 1316px + $padding * 2;

		.sub-image-container {
			padding: $padding;
			position: absolute;

			&-1 {
				top: 0;
				left: 0;
				width: 50%;
				height: 100%;
			}

			&-2 {
				right: 25%;
				top: 0;
				width: 25%;
				height: 50%;
			}

			&-3 {
				right: 0;
				top: 0;
				width: 25%;
				height: 50%;
			}

			&-4 {
				right: 0;
				bottom: 0;
				width: 50%;
				height: 50%;
			}

			.sub-link {
				height: 100%;
				left: 0;
				position: absolute;
				top: 0;
				width: 100%;

				&:hover {
					~ .sub-image-inner .btn .white-chevron-right {
						margin-left: 25px;
						transition: all .3s ease;
					}
				}
			}

			> .sub-image-inner {
				border-radius: 36px;
				background: grey;
				height: 100%;
				width: 100%;
				overflow: hidden;
			}
		}
	}

	.mod-hero-btn {
		position: absolute;
		bottom: $padding;
		justify-content: start;
		background-color: rgba($color-pink, 0.8);
		height: 72px;
		width: calc(100% - #{$padding * 2});
		font-size: $font-size-h3;
		border-radius: 0 0 36px 36px;
	}

	@include mq(tablet-down) {
		flex-direction: column;
		margin-top: 0;
		margin-bottom: 0;

		.sub-image-container {
			position: relative;
			height: 224px;
			width: 100%;
			padding: 0;
			margin-bottom: 16px;

			.sub-image-inner {
				height: 100%;
				border-radius: $container-radius-mobile;

				img {
					height: 100%;
					width: 100%;
					object-fit: cover;
					border-radius: $container-radius-mobile;
				}

				.mod-hero-btn {
					height: 43px;
					width: 100%;
					bottom: 0;
					left: 0;
					font-size: $font-size-large;
					border-radius: 0 0 $container-radius-mobile $container-radius-mobile;
					padding-left: 12px;

					.white-chevron-right {
						margin-left: 14px;
						height: 12px;
						width: 12px;
						transition: all .3s ease;
					}
				}
			}
		}
	}
}
