#subscribe-to-our-newsletter {
	@import "_section-form";

	.section-form {
		padding-bottom: 200px;

		.inner-form {
			height: auto;
			max-width: 680px;
			width: 100%;

			form {
				margin-top: 0;

				.terms {
					margin-top: 25px;
				}
			}
		}
	}
}
