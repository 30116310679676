#how-businesses-can-help {
	@import "section-hero-title";
	@import "section-content-and-quote";
	@import "section-full-width-cta";
	@import "section-hero-with-links";
	@import "section-large-quote";
	@import "_section-download-form";
	@import "partial-how-x-can-help";

	.section-full-width-cta {
		height: 105px;
		margin-bottom: 0;
		margin-top: 75px;
		padding: 0 35px;
		width: 100%;

		@include mq(tablet-down) {
			height: auto;
			padding: 20px 30px;
		}

		h2 {
			font-size: $font-size-larger-larger-larger;
			margin-bottom: 0 !important;
		}

		.mod-hero-btn {
			margin: 0 0 0 35px;

			@include mq(tablet-down) {
				margin: 0;
				margin-top: 20px;
			}
		}
	}

	.pledge-button {
		margin: 50px 0 25px;

		.btn {
			border-radius: 50px;
			font-size: $font-size-larger;
			height: 51px;
			width: auto;

			@include mq(mobile) {
				font-size: $font-size-med;
			}
		}
	}
}
