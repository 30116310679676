#home {
	@import "section-hero-image";
	@import "section-full-width-cta";
	@import "section-links-with-images";
	@import "section-large-quote";
	@import "../posts/section-news-cards";
	@import "section-testimonials";
	@import "_section-video-modal";

	.section-hero-image {
		position: relative;

		.hero-image-text-container {
			z-index: 99;
		}

		.hero-image-overlay {
			@extend %white-fade;
		}
	}

	.slick-dots {
		li {
			margin: 0;
			width: 16px;

			button {
				opacity: 1;
				background: $color-ngage-pink;
			}

			&.slick-active button {
				background: $color-light-pink;
				opacity: .8;
			}
		}
	}

	.mod-hero-btn {
		margin-top: 18px;
		width: unset;
		height: 51px;
		font-size: $font-size-larger;
		padding: 0 28px;
		border-radius: 30px;
	}
}
