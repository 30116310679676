.section-two-column-content {
	@extend %container-large;

	margin: 96px auto;

	h2 {
		margin-bottom: 38px;

		@include mq(mobile) {
			margin-bottom: 18px;
			text-align: center;
			font-size: $font-size-larger;
		}
	}

	.sub-content {
		@extend %flex-space-between;

		align-items: flex-start;

		> div {
			width: calc(50% - 24px);

			@include mq(mobile) {
				width: 100%;
			}
		}

		p {
			font-size: $font-size-larger;
			line-height: 42px;

			@include mq(mobile) {
				font-size: $font-size-small-med;
				line-height: 24px;
			}
		}

		@include mq(mobile) {
			flex-direction: column;
			text-align: center;
		}
	}

	@include mq(mobile) {
		margin: 32px auto 64px;
	}
}
