#jobs, #training, #placement, #apprentices {
	@import "section-hero-title";
	@import "section-content-and-quote";
	@import "section-large-quote";
	@import "section-hero-with-links";

	.section-content-and-quote {
		padding-bottom: 120px;
	}

	.section-large-quote {
		margin: 0;
		height: 463px;
		width: 100%;

		h2 {
			margin-top: 53px;
			color: $color-white;
			font-size: $font-size-larger-larger-larger;

			@include mq(tablet-down) {
				font-size: $font-size-h3;
			}
		}

		.sub-large-quote-container {
			border-radius: 0;

			p {
				font-weight: $font-weight-regular;
				margin-bottom: 53px;
				font-size: $font-size-larger-larger;
				line-height: 45px;

				@include mq(tablet-down) {
					line-height: 25px;
					font-size: $font-size-small-med;
				}
			}
		}

		@include mq(tablet-down) {
			height: 0;
			min-height: 396px;
		}
	}

	.section-content-and-quote .sub-quote {
		.mod-quote-cta-button {
			@include mq(tablet-down) {
				display: none;
			}
		}
	}
}
