form {
	@extend %flex-column;

	margin-top: 52px;

	input {
		border: 0;
		line-height: 38px;
		font-size: $font-size-med;
		color: $color-medium-dark-grey;

		@include mq(tablet-down) {
			font-size: $font-size-standard;
		}
	}

	textarea {
		border: 0;
		line-height: 30px;
		font-size: 1.14286rem;
		color: #686868;
	}

	.terms {
		margin: 50px 0;

		.term {
			align-items: flex-start;
			display: flex;

			input[type="checkbox"] {
				margin-right: 14px;
				width: 14px;
				margin-bottom: 0;
				line-height: 19px;
				margin-top: 4px;

				@include mq(tablet-down) {
					width: 12px;
				}
			}

			label {
				font-size: $font-size-standard;
				color: $color-medium-grey;

				@include mq(tablet-down) {
					font-size: $font-size-small;
				}
			}
		}
	}

	@include mq(tablet-down) {
		margin-top: 32px;
	}
}
