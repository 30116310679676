// Define placeholders within here to be used within further sass.
%container {
	width: $container-width;
	max-width: $container-max-width;
	margin: 0 auto;

	// Example of how to extend container. Used via `@extend %container-large`
	&-large {
		@extend %container;

		max-width: $container-max-width-large;
	}

	&-small {
		@extend %container;

		max-width: $container-max-width-small;
	}

	@include mq(mobile) {
		width: $container-width-mobile;
		max-width: $container-max-width-mobile;
		margin: 0 auto;
	}
}

@mixin container-large {
	width: $container-width;
	margin: 0 auto;
	max-width: $container-max-width-large;
}

%clearfix {
	zoom: 1;

	&:after {
		clear: both;
	}

	&:before,
	&:after {
		content: "";
		display: table;
	}
}

%noselect {
	-webkit-touch-callout: none;
	user-select: none;
}

%nofocus {
	&:focus {
		outline-style: none;
		box-shadow: none;
	}
}

%list-default {
	margin: 0;
	padding: 0;
	list-style: none;

	& > li {
		margin: 0;
		padding: 0;
	}
}

%button {
	@extend %flex-center;

	width: 120px;
	height: 40px;
	border-radius: 20px;
	background-color: $color-light-blue;
	color: white;
	font-weight: $font-weight-bold;
}

%flex-wrap {
	display: flex;
	flex-wrap: wrap;
}

%flex-center {
	display: flex;
	justify-content: center;
	align-items: center;
}

@mixin flex-center {
	display: flex;
	justify-content: center;
	align-items: center;
}

%flex-end {
	display: flex;
	justify-content: flex-end;
	align-items: flex-end;
}

%flex-space-around {
	display: flex;
	justify-content: space-around;
	align-items: center;
}

%flex-space-between {
	display: flex;
	justify-content: space-between;
	align-items: center;
}

@mixin flex-space-between {
	display: flex;
	justify-content: space-between;
	align-items: center;
}

%flex-column {
	display: flex;
	flex-direction: column;
}

%flex-row {
	display: flex;
	flex-direction: row;
}

%flex-grow {
	display: flex;
	flex: 1 0 auto;
}

%pink-chevron-down {
	background-image: url("data:image/svg+xml;utf8,<svg xmlns='http://www.w3.org/2000/svg' xml:space='preserve' fill='#{$url-safe-color-ngage-pink}' width='512' height='298' shape-rendering='geometricPrecision' text-rendering='geometricPrecision' image-rendering='optimizeQuality' fill-rule='evenodd' clip-rule='evenodd' viewBox='0 0 512 298.04'><path fill-rule='nonzero' d='M12.08 70.78c-16.17-16.24-16.09-42.54.15-58.7 16.25-16.17 42.54-16.09 58.71.15L256 197.76 441.06 12.23c16.17-16.24 42.46-16.32 58.71-.15 16.24 16.16 16.32 42.46.15 58.7L285.27 285.96c-16.24 16.17-42.54 16.09-58.7-.15L12.08 70.78z'/></svg>");
	height: 10px;
	width: 10px;
	display: flex;
	background-size: contain;
	background-repeat: no-repeat;
	justify-content: center;
	background-position: center;
}

%white-chevron-down {
	background-image: url("data:image/svg+xml;utf8,<svg xmlns='http://www.w3.org/2000/svg' xml:space='preserve' fill='white' width='512' height='298' shape-rendering='geometricPrecision' text-rendering='geometricPrecision' image-rendering='optimizeQuality' fill-rule='evenodd' clip-rule='evenodd' viewBox='0 0 512 298.04'><path fill-rule='nonzero' d='M12.08 70.78c-16.17-16.24-16.09-42.54.15-58.7 16.25-16.17 42.54-16.09 58.71.15L256 197.76 441.06 12.23c16.17-16.24 42.46-16.32 58.71-.15 16.24 16.16 16.32 42.46.15 58.7L285.27 285.96c-16.24 16.17-42.54 16.09-58.7-.15L12.08 70.78z'/></svg>");
	height: 10px;
	width: 10px;
	display: flex;
	background-size: contain;
	background-repeat: no-repeat;
	justify-content: center;
	background-position: center;
}

%white-fade {
	background: linear-gradient(270deg, hsla(0, 0%, 100%, 0), hsla(0, 0%, 100%, 0.94) 90%, #fff);
	content: "";
	height: 100%;
	left: 0;
	position: absolute;
	top: 0;
	width: 100%;
	z-index: 1;
}
